:root {
  --breakpoint: 30rem;
  --page-width: 46rem;
  --thin-crust: 2rem;
  --deep-dish: 5rem;
}

body {
  background: #ebebeb;
  margin: 0;
  font-family: Arial, sans-serif;
  font-size: 16px;
  line-height: 1.5;
}

@media (prefers-color-scheme: dark) {
  body {
    color: #fff;
    background: #141414;
  }
}

svg {
  fill: #000;
}

@media (prefers-color-scheme: dark) {
  svg {
    fill: #fff;
  }
}

h1, h2, h3, h4, h5, h6 {
  text-align: center;
}

h1 {
  margin-top: 0;
  font-size: 3.052rem;
}

.h1 {
  font-size: clamp(1.953rem, 1.5vw, 3.052rem);
}

h2 {
  font-size: 2.441rem;
}

.h2 {
  font-size: clamp(1.25rem, 1.5vw, 2.441rem);
}

h3 {
  font-size: 1.953rem;
}

.h3 {
  font-size: clamp(1.25rem, 1.5vw, 1.953rem);
}

h4 {
  font-size: 1.563rem;
}

h5 {
  font-size: 1.25rem;
}

small, .text_small {
  font-size: .8rem;
}

a {
  color: #43c6ac;
}

a:hover {
  color: #00957d;
  text-decoration: line-through;
}

ul {
  line-height: 2em;
}

blockquote {
  font-style: italic;
}

.screen-reader {
  width: 0;
  height: 0;
  overflow: hidden;
}

.block {
  display: block;
}

.full-width, .full-width img {
  z-index: -1;
  width: 100%;
  max-width: 100%;
}

.container {
  max-width: var(--page-width);
  padding-inline: 1rem;
  background: #fafafa;
  border: 1px solid #fff;
  border-radius: 3px;
  margin-inline: auto;
  padding-bottom: 1.5rem;
  box-shadow: 0 0 1rem 1rem #fafafa;
}

@media (prefers-color-scheme: dark) {
  .container {
    background: #0a0a0a;
    border-color: #000;
    box-shadow: 0 0 1rem 1rem #050505;
  }
}

.grid {
  display: grid;
}

.grid > * {
  grid-area: 1 / 1;
}

.io-push {
  animation-timing-function: ease;
}

@media (prefers-color-scheme: dark) {
  picture {
    opacity: .9;
  }
}

.io-parallax {
  clip-path: polygon(0 var(--thin-crust), 100% 0, 100% calc(100% - 7rem), 0 calc(100% - 5rem));
  animation-timing-function: ease;
}

@media (width >= 30rem) {
  .io-parallax {
    clip-path: polygon(0 var(--deep-dish), 100% 0, 100% calc(100% - 12rem), 0 calc(100% - 5rem));
    max-height: 40rem;
  }
}

header {
  justify-items: center;
  display: grid;
  overflow: hidden;
}

header > * {
  grid-area: 1 / 1;
}

header picture {
  clip-path: polygon(0 0, 100% 0, 100% calc(100% - 4rem), 0 calc(100% - var(--thin-crust)));
  min-height: 10rem;
  animation-timing-function: ease;
}

@media (width >= 30rem) {
  header picture {
    clip-path: polygon(0 0, 100% 0, 100% calc(100% - var(--deep-dish)), 0 100%);
    max-height: 35rem;
  }
}

.header-logo {
  aspect-ratio: 1;
  background: #000000bf;
  border-radius: 50%;
  max-width: 50vw;
  margin-block-start: 1rem;
  padding: .5rem;
}

@media (width >= 30rem) {
  .header-logo {
    max-width: 33vw;
    padding: 1rem;
  }
}

footer {
  clip-path: polygon(0 var(--thin-crust), 100% 0, 100% 100%, 0 100%);
}

@media (width >= 30rem) {
  footer {
    clip-path: polygon(0 var(--deep-dish), 100% 0, 100% 100%, 0 100%);
  }
}

footer picture {
  animation-timing-function: ease;
}

.footer-img {
  z-index: -2;
  background: #000;
}

.footer-text {
  text-align: center;
  color: #fff;
  box-shadow: none;
  background: #000000bf;
  border-radius: 2rem;
  place-self: center;
  margin-block: 5rem;
}

#services, #testimonial__wrap {
  box-shadow: none;
  background: none;
  border: none;
}

#services, #testimonials {
  grid-gap: 2rem;
  grid-template-columns: repeat(auto-fill, minmax(22rem, 1fr));
  max-width: 46rem;
  display: grid;
}

.service, .testimonial {
  grid-auto-rows: min-content;
  display: grid;
}

.service > *, .testimonial > * {
  grid-column: 1;
  align-self: start;
  padding-left: 1em;
  padding-right: 1em;
}

.service .icon {
  place-self: center;
  margin-top: 2em;
}

.service p, .service .multi-p {
  align-self: stretch;
  margin-bottom: 2em;
}

.service .icon, .testimonial p {
  grid-row: 1;
}

.service h3, .testimonial blockquote {
  grid-row: 2;
}

.service p, .service .multi-p {
  grid-row: 3;
}

.testimonial p {
  grid-column: 1;
  margin-top: 3em;
}

.testimonial blockquote {
  grid-column: 1;
  margin-bottom: 3em;
}

.skewer {
  background: linear-gradient(15deg, #43c6ac54 0%, #f8ffae54 100%);
  border-radius: 10px;
  grid-area: 1 / 1 / 4;
  place-self: stretch stretch;
  transform: skewY(-7deg);
}

/*# sourceMappingURL=index.fe822007.css.map */
